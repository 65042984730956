@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Nunito&display=swap');

html {
  width: 100%;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: Nunito;
  background-color: #313338;
  color: #fff;
}

#root {
  width: 100%;
  height: 100%;
}

.rectangle {
  background-color: #1E1F22;
  width: 120%;
  height: 80%;
  position: fixed;
  transform: rotate(-12deg);
  top: -35%;
  left: -20%;
  z-index: -999;
}

.title {
  font-family: Montserrat;
  position: absolute;
  top: 7%;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  z-index: 999;
}

.card {
  width: 550px;
  height: 600px;
  background: #D9D9D9;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.scroll-content {
  width: 550px;
  height: 550px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888888 #D9D9D9;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888888;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #D9D9D9;
  }
}

.backButton {
  background: #000;
  padding: 10px 20px 66px 10px;
  border-radius: 17px;
  position: absolute;
  top: -38px;
  left: 0px;
  z-index: -1;
  font-weight: 700;
  cursor: pointer;
  transition: 0.2s;
  user-select: none;
}

.backButtonContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.backButton:hover {
  filter: brightness(0.4);
}

.backButtonIcon {
  padding-right: 5px;
}

.mobileTitle {
  display: none;
}

.select {
  background: #b8b8b8;
  color: #000;
  width: 380px;
  height: 27px;
  padding: 10px;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  border-radius: 12px;
  user-select: none;
  cursor: pointer;
  position: relative;
  transition: 0.2s;
  border: 1px solid;
}

.option {
  background: #b8b8b8;
  color: #000;
  width: 380px;
  height: 27px;
  padding: 10px;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  position: relative;
  transition: 0.2s;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.wrapper {
  position: absolute;
  top: 14%;
}

.selectContainer:first-child {
  margin-bottom: 20px;
}

.feedbackTextArea {
  margin-top: 35px;
  background: #b8b8b8;
  border: none;
  border-radius: 12px;
  width: 382px;
  height: 140px;
  padding: 10px;
  outline: none;
  font-family: Nunito;
  font-size: 20px;
  resize: none;
}

.inputContainer {
  position: absolute;
  top: 14%;
}

.messageBox:last-child {
  margin-bottom: 80px;
}

.textAreaContainer {
  background: #d9d9d9;
  position: absolute;
  bottom: 20px;
  width: calc(100% - 10px);
  padding-top: 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sendBtn {
  background: #000;
  border: none;
  border-radius: 9px;
  padding: 9px 35px 9px 35px;
  color: #fff;
  font-family: Nunito;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  transition: 0.2s;
  position: absolute;
  bottom: 10%;
}

@media (max-width: 600px) {
  body {
    background-color: #1E1F22;
  }

  .title {
    font-size: 27px;
    top: 4%;
  }

  .textAreaContainer {
    bottom: 0;
    padding-bottom: 20px;
  }

  .messageBox:last-child {
    margin-bottom: 110px;
  }

  .select, .option {
    width: auto;
  }

  .selectContainer, .options {
    width: 85%;
  }

  .feedbackTextArea {
    width: 80%;
  }

  .inputContainer {
    position: absolute;
    top: 15%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .card {
    width: 100%;
    height: 80%;
    position: absolute;
    bottom: 0;
    border-radius: 25px 25px 0px 0px;
  }
  

  .scroll-content {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
  }

  .scroll-content::-webkit-scrollbar {
    display: none;
  }

  .sendBtn {
    bottom: 15%;
  }

  .messageBox:first-child {
    margin-top: 20px;
  }
}